@use 'sass:math';

/* SitePoint
================================================= */

$pdf-offset: $grid-column;

.block-popout {
  position: relative;
  margin-top: 45px;
  margin-bottom: -(30px + 30px);
  border-bottom: 1px solid mix(#5FAC6C, #E5F7BF, 25%);
  border-radius: 5px 5px 0 0;
  padding: (math.div($grid-gutter, 3) * 2) $grid-gutter $grid-gutter;
  background-color: #E5F8BC;
  color: #617F1F;
  z-index: 200;
  box-shadow: 0 5px 10px rgba(#000, .25);
}


// PDF guide preview

.guide-preview {
  float: right;
  position: relative;
  width: 346px;
  height: 260px;
  margin-top: .25em;
  margin-right: -$pdf-offset;
  background: url('../images/landing/guide-preview-bg@2x.png') no-repeat;
  background-size: 346px 260px;

  img {
    position: absolute;
    top: 2px;
    left: 13px;
    width: 330px;
    height: 255px;
  }
}
  .guide-preview_cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 346px;
    height: 260px;
    background: url('../images/landing/guide-preview-bg@2x.png') no-repeat;
    background-size: 346px 260px;
  }


// Form

.form-subscribe {
  margin: 30px 0 0;
}
  .form-subscribe_wrap {
    margin: 0;
    border-width: 1px;
    border-style: solid;
    padding: 1em 1.25em .5em;
  }
  .form-subscribe_title {
    margin: 0 -.5em;
    padding: 0 .5em;
    font-weight: bold;
    font-size: 1.1em;
    line-height: 1.2;
  }

  .form-subscribe_field {
    width: 24em;
    margin: 0;
    border: 1px solid #DDD;
    padding: .3em .5em;
    color: #444;
    vertical-align: middle;

    &::placeholder {
      color: #CCC;
      font-weight: 200;
    }
  }
  .form-subscribe_note {
    font-size: .85em;
  }

.form-subscribe--hero {
  width: 560px;

  .form-subscribe_wrap {
    border-color: rgba(#617F1F, .2);
    background-color: #F2FBDF;
    box-shadow: 0 2px 3px rgba(#66806A, .075);
  }
  .form-subscribe_title {
    background-color: #E5F7BF;
    background-image: linear-gradient(to bottom, #E5F7BF 20%, #F2FBDF 100%);
  }
  .form-subscribe_field {
    width: 22em;
  }
}

.form-subscribe--standalone {
  .form-subscribe_wrap {
    border-color: rgba(#D8FAD5, .5);
  }
  .form-subscribe_title {
    color: #FFF;
    font-size: 1em;
  }
  .form-subscribe_note {
    font-size: .75em;
  }
}

.form-subscribe--cr {
  .form-subscribe_wrap {
    border-color: #9ADBCB;
    background-color: #C4EEDB;
    box-shadow: 0 2px 3px rgba(#6B9899, .075);
  }
  .form-subscribe_title {
    background-color: #B0E9CF;
    background-image: linear-gradient(to bottom, #B0E9CF 20%, #C4EEDB 100%);
  }
}
