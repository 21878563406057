@mixin hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.wildbit-tag {
  display: none;
  position: absolute;
  z-index: 10000;
  top: 30px;
  left: 0px;
  width: 24px;
  height: 119px;
  background-color: rgba(255,255,255,0.6);
  background-image: url('../images/landing/wildbit/made-by-tag.png');
  background-repeat: no-repeat;
  background-size: 18px 111px;
  background-position: 3px 3px;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  transition: all 100ms ease;

  @include medium {
    display: block;
  }

  &.is-active {
    transform: translateX(-24px);
  }
}

.is-visible .wildbit-tag {
  background-color: rgba(255,255,255,0.92);
}

.wildbit-drawer {
  display: none;
  position: fixed;
  z-index: 10001;
  top: 0;
  bottom: 0;
  left: -400px;
  width: 326px;
  padding: 35px 37px;
  background-color: rgba(255,255,255,0.92);
  box-shadow: 10px 0 35px rgba(0,0,0,0.1);

  &.is-visible {
    display: block;
    left: 0;
  }

  &.animate-in {
    display: block;
    transform: translateX(400px);
    animation-name: slide;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.3, 0.3, 0, 1);
  }

  &.animate-out {
    display: block;
    animation-name: slide;
    animation-direction: reverse;
    animation-duration: 300ms;
    animation-timing-function: ease-out;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(400px);
  }
}

.wildbit-drawer-dimmer {
  display: none;
  position: fixed;
  z-index: 10000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.6);
  opacity: 0;
  animation-name: fade;
  animation-duration: 400ms;

  &.is-visible {
    display: block;
    opacity: 1;
  }

  &.animate-in {
    display: block;
    animation-name: fade;
    animation-duration: 400ms;
    animation-timing-function: ease;
  }

  &.animate-out {
    display: block;
    opacity: 1;
    animation-name: fade;
    animation-direction: reverse;
    animation-duration: 300ms;
    animation-timing-function: ease;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wildbit-drawer {
  color: #363839;

  .wildbit-drawer-close {
    position: absolute;
    top: 20px;
    right: -42px;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url('../images/landing/wildbit/btn-close-x.png');
    background-size: 20px 20px;
    @include hide-text;

    @include retina {
      background-image: url('../images/landing/wildbit/btn-close-x@2x.png');
    }
  }

  .wildbit-drawer-logo-main {
    margin: 0 auto 35px;
    width: 147px;
    height: 42px;
    background-image: url('../images/landing/wildbit/logo-wb.png');
    background-size: 147px 42px;
    @include hide-text;

    @include retina {
      background-image: url('../images/landing/wildbit/logo-wb@2x.png');
    }
  }

  .wildbit-drawer-intro {
    font-weight: 200;
    font-size: 16px;
    padding-bottom: 42px;
    text-align: center;

    nav {
      text-transform: uppercase;
      text-align: center;
      font-weight: 700;
      font-size: 76%;
    }

    nav a {
      margin: 0 8px;
    }
  }

  a {
    color: #439a4d;
    text-decoration: none;
  }

  .wildbit-drawer-product {
    padding: 0;
    margin-bottom: 22px;
    text-align: center;
    border: 1px solid rgba(#ddd, 1);
    border-radius: 5px;
    transition: all 300ms ease;

    &:hover {
      background-color: rgba(black, 0.02);
      border: 1px solid #ccc;
    }
    
    a {
      display: block;
      padding: 8px 0 9px;
    }

    h4 {
      margin: 0 auto;
    }

    p {
      font-size: 13px;
      text-align: left;
      margin-bottom: 0;
      text-align: center;
      color: #767879;
    }
  }

  .wildbit-drawer-learn-more {
    margin: 0 auto;
    font-size: 12px;
    display: inline-block;
    padding: 4px 12px 3px;
    border: 1px solid #8dba92;
    border-radius: 18px;
  }

  .wildbit-drawer-logo--beanstalk {
    margin: -3px auto -6px !important;
    width: 111px;
    height: 34px;
    background-image: url('../images/landing/wildbit/logo-beanstalk.png');
    background-size: 111px 34px;
    @include hide-text;

    @include retina {
      background-image: url('../images/landing/wildbit/logo-beanstalk@2x.png');
    }
  }

  .wildbit-drawer-logo--postmark {
    margin: 8px auto -5px !important;
    width: 117px;
    height: 19px;
    background-image: url('../images/landing/wildbit/logo-postmark.png');
    background-size: 117px 19px;
    @include hide-text;

    @include retina {
      background-image: url('../images/landing/wildbit/logo-postmark@2x.png');
    }
  }

}

