// Grid

@function grid-width($columns) {
  @return ($grid-column * $columns) + ($grid-gutter * ($columns - 1));
}


// @media queries

@mixin retina {
	@media (-webkit-min-device-pixel-ratio: 1.5),
	          (min--moz-device-pixel-ratio: 1.5),
                (-o-min-device-pixel-ratio: 3/2),
                           (min-resolution: 144dpi),
                           (min-resolution: 1.5dppx) {
		@content;
	}
}


// 480-750: Mobile to portrait table
@mixin small {
  @media only screen and (min-width: 480px) {
    @content;
  }
}

// 750-1020: Landscape tablet to small screen
@mixin medium {
  @media only screen and (min-width: 750px) {
    @content;
  }
}

// 1020+: Small screen to big screen
@mixin large {
  @media only screen and (min-width: 1020px) {
    @content;
  }
}
