// Webfonts

@font-face {
  font-family: 'Alice';
  font-style: normal;
  font-weight: 400;
  src: local('Alice'),
       local('Alice-Regular'),
       url('data:application/font-woff;base64,d09GRgABAAAAAAV8AA4AAAAABdQAAQABAAAAAAAAAAAAAAAAAAAAAAAAAABHUE9TAAABRAAAAF4AAABe2DvnSEdTVUIAAAGkAAAADAAAAAwAFQAKT1MvMgAAAbAAAABgAAAAYDvO7I5jbWFwAAACEAAAADQAAAA0AAwAeWdhc3AAAAJEAAAADAAAAAwABwAHZ2x5ZgAAAlAAAAFAAAABZNFGQrxoZG14AAADkAAAAJMAAACsqRRBQGhlYWQAAAQkAAAANgAAADb38X9vaGhlYQAABFwAAAAkAAAAJAdtAtlobXR4AAAEgAAAAAgAAAAIBHYAKWxvY2EAAASIAAAABgAAAAYAsgAAbWF4cAAABJAAAAAgAAAAIAIiA9ZuYW1lAAAEsAAAAKkAAAEgEs8u2XBvc3QAAAVcAAAAIAAAACD/uAAyAAEAAAAKAB4ALAABbGF0bgAIAAQAAAAA//8AAQAAAAFrZXJuAAgAAAABAAAAAQAEAAIAAAABAAgAAgAYAAQAAAAeAB4AAgACAAAAAAAAAAAAAQABAAEAAQABAAEAAQAAAAEAAAAKAAoACgAAAAMCCgGQAAUAAAK8AooAAACMArwCigAAAd0AMgD6AAACAAUDCAAAAgAEgAAAJ0AAAEMAAAAAAAAAACAgICAAQAAg+wEDjf8WAAADjQDqAAAAAwAAAAABygJ6AAAAIAACAAAAAgAAAAMAAAAUAAMAAQAAABQABAAgAAAABAAEAAEAAAAm//8AAAAm////2wABAAAAAAAAAAIACAAC//8AA3icJc1bToNAGAVg/pm2XMLVDlBKmQbGQGktaRmQRFMMJpo2MamXmL5o1MQF6AJ888UVuggXoaiv5zsnRwAh/f7Cj+hTeBcEKDLEIh25FFynRpyCqAOL4rKoO1UNSQZJrCOWQVUjx/1VzCjYREdJhtsSqsoMyuKQ5xTZpPe/RGURs6hnE5fnjkt6M9CxTSjieY3Qh0nngUqHFoBpabYho66FO4kfZ5OTq+uLkUNbIC0o0AJ2x8z0CUB6fsenB+X92/r4ZVfYaZ2G9OHWL5IBC52BoilTyvNVqBogK4wtQ4PVO149baYQ+MXEk0zP0h2MJFmGke0Hq0XRJH1FlqWAqHsYxL+ceCqIWjlMm7nLbvj6dZsCjJvnM9aU+yL2jqLTS3WY+HThdEHS+xqNVpNN2CGygYNZ6S+3VdD+Cj86bi31eJwNwwUShCAUAFBmpT5hYGJii27e/3Lrm3kIIY8QwsAfXEAAUgRCyUDqe6iwijTWcYjDJCKRiUmcJiTJDDG5oaZIaVpmNKtymtuCFXXJyqZiVWuZ7Sy3fc3roeGNa6EdO+imHvp5gGFxwq33bRTjPonpmOXsF7mcq1yvTW7PXe2v+/tQx8dr/z31+bv09QeYDA42AAABAAAAAQKP1pgI+F8PPPUAGQPoAAAAAMpznE0AAAAAynOdPP/u/xYD7wNbAAAACQACAAAAAAAAAAEAAAON/xYAAAQg/+7/oAPvAAEAAAAAAAAAAAAAAAAAAAACAQsAAANrACkAAAAAALIAAAABAAAAAgDnAA4AhwAEAAEAAAAAAAoAAAIAAmYAAwABeJxdjz0LwkAQRF9MVLRILVaWWpgPsbITwUoQLCxSCCIhCsGERFH/hr/YiR4kyHDHm73ZXQ7oEmFjOT0s+mC4hSv3Y5s1A8NOI9NmyMFwR/w07DLnzZKUCydidjoJd/kjBSteumNxypjHV55yddUjEydMWDBqzKncjIBQ2ssXlHrJuKoeqqt6CRr56d/mMzcp1xxfKpUplMxVK9Vdzap3+2z1680HesYlxQAAAAADAAAAAAAA/7UAMgAAAAAAAAAAAAAAAAAAAAAAAAAA') format('woff');
}

.wf-loading {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .btn-signup {
    visibility: hidden;
  }
}
.wf-active {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .btn-signup {
    visibility: visible;
  }
}


// Headers

.h-alpha,
.h-beta,
.h-gamma,
.h-delta,
.h-epsilon {
  font-weight: bold;
  line-height: 1.2;
}
.h-alpha {
  font-size: 2em;
  line-height: 1.1;

  @include medium {
    font-size: 2.8em;
  }
}
.h-beta {
  font-size: 1.8em;

  @include medium {
    font-size: 2.25em;
  }
}
.h-gamma {
  font-size: 1.5em;
}
.h-delta {
  font-size: 1.35em;
}
.h-epsilon {
  font-size: 1.25em;
}

.h-loose,
.h-normal,
.h-tight {
  &:first-child {
    margin-top: 0;
  }
}
.h-loose {
  margin: 1.5em 0 .9em;
}
.h-normal {
  margin: 1em 0 .6em;
}
.h-tight {
  margin: .5em 0 .3em;
}


// Base

body {
  background-color: #5FAC6C;
  font: 1em/1.4 'proxima-nova', 'Helvetica Neue', sans-serif;

  @include large {
    font-size: 1.0625em;
  }
}

a {
  color: inherit;
}

iframe {
  border: 0;
}

.list-flat {
  padding-left: 0;
  list-style: none;

  > li {
    margin-bottom: .5em;
  }
}
.list-loose {
  > li {
    margin-bottom: .75em;
  }
}


// Title

.section-head {
  margin: 0 0 30px;
  overflow: hidden;

  @include medium {
    display: table;
    margin-bottom: 60px;
  }
}
  .section-head_title,
  .section-head_text {
    margin: 0;
    vertical-align: middle;

    @include medium {
      display: table-cell;
      width: 455px;
    }
  }
  .section-head_title {
    @extend .h-beta;

    @include medium {
      border-right: 1px solid #BBB;
      padding-right: 34px;
      text-align: right;
    }

    .nowrap {
      white-space: normal;

      @include large {
        white-space: nowrap;
      }
    }
  }
  .section-head_text {
    font-size: 1.25em;
    line-height: 1.33;

    @include medium {
      padding-left: 35px;
    }
  }

.section-head--midsize {
  margin-bottom: 30px;

  .section-head_title {
    font-size: 1.75em;
  } 
}

.section-head--vertical {
  display: block;

  .section-head_title {
    display: block;
    width: auto;
    border-right: 0;
    padding-right: 0;
    text-align: center;
  }
  @include large {
    .nowrap {
      display: block;
    }
  }
}


/* Buttons
================================================= */

.button {
  display: inline-block;
  border: 0;
  border-radius: 2px;
  padding: .3em .9em;
  background-color: #CC475D;
  background-image: linear-gradient(to bottom, #D9576C 0%, #CC475D 100%);
  color: #FFF;
  font-weight: bold;
  font-size: .9em;
  text-shadow: 0 1px 1px rgba(#000, .33);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: inset 0 1px 0 0 #ED8E9E, inset 0 -1px 0 1px rgba(#BF3048, .5);

  &:hover,
  &:active {
    background-color: #000;
    background-image: linear-gradient(to bottom, #E5677C 0%, #D94C63 100%);
  }
}


.signup-block {
  margin: 45px 0;
  text-align: center;
}
  .btn-signup {
    box-sizing: border-box;
    display: inline-block;
    border-radius: 3px;
    padding: .9em 2em;
    background-color: #CC475D;
    background-image: linear-gradient(to bottom, #D9576C 0%, #CC475D 100%);
    color: #FFF;
    font-weight: bold;
    font-size: 1.5em;
    text-shadow: 0 1px 1px rgba(#000, .33);
    text-align: center;
    text-decoration: none;
    box-shadow: inset 0 1px 0 0 #ED8E9E, inset 0 -1px 0 1px rgba(#BF3048, .5), 0 1px 1px 1px rgba(#000, .1);

    &:hover,
    &:active {
      background-color: #000;
      background-image: linear-gradient(to bottom, #E5677C 0%, #D94C63 100%);
    }

    @include small {
      .btn-signup {
        width: 350px;
      }
    }
  }



/* Misc
================================================= */

.hidden {
  display: none !important;
}

.nowrap {
  white-space: nowrap;
}

.amp {
  font-family: 'Alice';
}

.clearfix::before,
.clearfix::after {
  content: ' ';
  display: table;
}
.clearfix::after {
  clear: both;
}

.plain {
  font-weight: 200;
}

.inline-list {
  padding-left: 0;
  list-style: none;

  li {
    display: inline-block;
    margin: 0 .75em;
  }
}

.u-cutout {
  overflow: hidden;
}

.u-top-attach {
  margin-top: 0;
}
.u-btm-attach {
  margin-bottom: 0;
}
