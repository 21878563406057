@use 'sass:math';

/* Layout
================================================= */

.wrapper {
  clear: both;
  float: left;
  position: relative;
  width: 100%;
}
  .container {
    max-width: grid-width(6);
    margin: 0 auto;
    padding: 30px 15px;

    @include medium {
      padding: 60px 30px;
    }
  }


// Announcement

.announce {
	padding: 20px 15px;
	border-bottom: 1px solid mix(#5FAC6C, #FFF, 75%);
	background-color: #085B4F;
	background-image: linear-gradient(to bottom, #085B4F, darken(#085B4F, 1%));
	color: mix(#085B4F, #FFF, 20%);
	text-align: center;

  @include medium {
    padding: 30px;
  }

	p {
		margin: 0;
	}
	a,
	strong {
		color: #FFF;
	}
}



// Text block

.text-block {
  max-width: 630px;
  margin: 30px auto;
  line-height: 1.55;

  q {
    font-style: italic;
  }
  code {
    margin: 0 .15em;
    font-family: Consolas, 'Lucida Console', Courier, monospace, serif;
    font-size: .95em;
    font-style: normal;
  }
}
.text-block--enlarge {
  @include medium {
    font-size: 1.176em;
    line-height: 1.35;
  }
}
.text-block--top-attach {
  margin-top: 0;
}
.text-block--top-detach {
  margin-top: 60px;
}
.text-block--btm-attach {
  margin-bottom: 0;
}
.text-block--btm-detach {
  margin-bottom: 60px;
}

  .signature {
    margin-top: 1.5em;
    font-size: .8em;
    opacity: .5;
  }


  // Customer story


  .story-block {
    @extend .clearfix;

    max-width: grid-width(5);
    margin: 35px auto;
    line-height: 1.55;

    .h-epsilon {
      margin: 1em 0 .5em;
      line-height: 1.4;
    }
    p {
      margin: 0;
    }
  }


  .story-logo {
    display: block;
    margin: .2em 0 .75em;
    padding: 60px 0;
    background-color: #FFF;
    text-align: center;

    img {
      vertical-align: middle;
    }

    @include small {
      float: left;
      width: 350px;
      margin-right: 35px;
    }
    @include large {
      margin-left: -175px;
    }
  }
  .story-logo--half {
    width: grid-width(2);
    margin-left: 0;
  }
  .story-logo--compact {
    padding: 40px 0;
  }
  .story-logo--square {
    padding: 60px;

    @include small {
      width: 100px;
    }
    @include large {
      margin-left: -110px;
    }
  }

  .floating-userpic {
    width:  70px;
    height: 70px;
    margin-top: .33em;
    border-radius: 50%;
  }
  .floating-userpic--left {
    float: left;
    margin-right: 30px;

    @include large {
      margin-left:  -(95px + math.div(70px, 2));
    }
  }
  .floating-userpic--right {
    float: right;
    margin-left: 30px;

    @include large {
      margin-right: -(95px + math.div(70px, 2));
    }
  }

  .callout-quote {
    margin: 1.75em 30px 1.6em;
    color: mix(#617F1F, #389458, 33%);
    font-weight: bold;
    font-size: 1.65em;
    line-height: 1.3;

    @include large {
      margin-right: 0;
      margin-left: -95px;
    }

    q {
      font-style: normal;

      &::before {
        margin-left: -.433em;
      }
    }
  }

  .figure {
    margin: 2em 0;
    border-radius: 5px;
    background-color: rgba(#FFF, .5);
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(#000, .07);

    img {
      display: block;
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }
  .figure--narrow {
    img {
      max-width: 600px;
    }
  }
  .figure--wide {
    @include large {
      margin-right: -95px;
      margin-left:  -95px;
    }

    img {
      max-width: (600px + 95px * 2);
    }
  }
  .figure--browser {
    border: 1px solid #CCC;
    padding-top: 36px;
    background: #FFF url('../images/landing/browser-toolbar.svg') no-repeat;
  }


// Features

.features-group {
  margin: 30px 0;

  @include medium {
    display: flex;
    flex-direction: row;
  }
}
  .feature {
    margin-bottom: 30px;
    text-decoration: none;

    @include medium {
      display: flex;
      flex-direction: column;
      margin-left: 35px;
    }
    @include large {
      margin-left: 70px;
    }

    &:first-of-type {
      margin-left: 0;
    }
  }
    .feature_title {
      @extend .h-delta;

      margin: 0 0 .33em;
    }
    .feature_desc {
      h3 {
        margin: 1em 0 .5em;
        font-size: 1em;
      }
      p {
        margin: 0 0 .5em;
      }

      @include medium {
        flex: 1 0 auto;
      }
    }
    .feature_screenshot {
      margin: 20px auto 0;
      border-radius: 5px;
      background-color: rgba(#FFF, .5);
      overflow: hidden;
      box-shadow: 0 2px 4px 0 rgba(#000, .07);

      @include medium {
        align-self: flex-end;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        vertical-align: middle;
      }
    }
    .feature_screenshot--browser {
      max-width: grid-width(6);
      border: 1px solid #CCC;
      padding-top: 36px;
      background: #FFF url('../images/landing/browser-toolbar.svg') no-repeat;

      @include medium {
        align-self: center;
      }
    }
    @include medium {
      .feature--fullsize {
        max-width: grid-width(6);

        .feature_screenshot img {
          max-width: 980px;
          // height: 605px; // 980/1.618 = ~605
        }
      }
      .feature--half {
        max-width: grid-width(3);

        .feature_screenshot img {
          max-width: 455px;
          // height: 280px; // 455/1.618 = ~281
        }
      }
      .feature--third {
        max-width: grid-width(2);

        .feature_screenshot img {
          max-width: 280px;
          // height: 175px; // 280/1.618 = ~173
        }
      }
    }


// Slides

.features-group--slides {
  margin-bottom: 45px;

  @include medium {
    flex-wrap: wrap;
    justify-content: center;
  }
  @include large {
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 643px;  // Match .feature_screenshot height
  }

  .feature {
    display: block;
    margin-top: .75em;
    margin-bottom: 45px;

    @include large {
      margin-right: 0;
      margin-left: 0;
    }

    &:first-of-type {
      margin-left: 0;
    }
  }

    .feature_title {
      position: relative;
      overflow: hidden;

      @include large {
        margin-right: -$grid-gutter;
      }
    }
      .feature_pointer {
        $distance: 15px;

        position: absolute;
        top: -100px;
        width: 300px;
        height: 10px;
        margin-top: .33em;
        margin-left: 250px;
        background-image: linear-gradient(to bottom, #49A8AB, #49A8AB);
        background-position: $distance 50%;
        background-repeat: no-repeat;
        background-size: 100% 2px;

        @include large {
          top: 0;
        }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: $distance;
          width:  8px;
          height: 8px;
          margin-top: -6px;
          border: 2px solid #269699;
          border-radius: 50%;
          background-color: #D5F1F1;
        }
      }

    .feature_screenshot {
      width: 100%;
      max-width: 980px;
      margin-top: 0;
      margin-bottom: 45px;
      overflow: hidden;

      @include large {
        position: absolute;
        right: 0;
        left: 50%;
        width: auto;
        margin-left: -(grid-width(1) + math.div($grid-gutter, 2));

        img {
          width: grid-width(6);
        }
      }
    }
      .feature_slides {
        position: relative;
        padding-top: 61.735%; // 605px / 980px * 100%
        overflow: hidden;

        @include large {
          width: grid-width(6);
          height: 605px;
          padding-top: 0;
        }
      }
        .feature_slides_wrap {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: -200%;

          @include large {
            img {
              width: 980px;
              height: 605px;
            }
          }
        }
}



// Videos

.video {
  margin: 60px 0;
  box-shadow: 0 0 0 3px rgba(#000, .07);

  iframe {
    vertical-align: middle;
  }
}
.video--hero {
  margin: 30px 0;
  background-color: #4AA360;
}


/* Hero
================================================= */

.hero {
  background-color: #5CAD69;
  color: mix(#B2F6AC, #FFF, 50%);

  .container {
    padding-top: 0;
  }
}

  // Logo & Sign In

  .landing-nav {
    position: relative;
    min-height: 70px;
    padding: 15px 0;
    text-align: center;

    @include medium {
      padding: 0;
      text-align: left;
    }
  }
  .landing-nav--detached {
    min-height: 0;
    margin: 15px 0;

    @include medium {
      margin: 30px 0 45px;
    }
  }
    .beanstalk-logo {
      width: 151px;
      height: 46px;

      @include medium {
        position: absolute;
        bottom: 0;
        left: -13px;
      }
    }
    .beanstalk-shopify-logo {
      width: 100%;
      max-width: 426px;
      height: auto;

      @include medium {
        display: block;
        margin: 0 auto;
      }
    }
    .landing-nav_items {
      margin: .5em 0;
      padding: 0;
      color: #B9EEB5;
      list-style: none;

      @include medium {
        position: absolute;
        right: 0;
        bottom: -5px;
        margin: 0;
      }

      li {
        display: inline-block;
        margin: .5em;

        @include medium {
          float: left;
          margin: 0 0 0 30px;
        }

        &:first-child {
          margin-left: 0;
        }
      }
      a {
        color: inherit;

        &:hover {
          color: #FFF;
        }
      }
    }

      a.btn-login,
      a.landing-nav_signup {
        border-radius: 2px;
        border-width: 2px;
        border-style: solid;
        padding: 5px 10px;
        font-weight: bold;

        &:hover {
          border-color: #FFF;
        }
      }
      a.btn-login {
        border-color: #E7FF8A;
        color: #E7FF8A;
      }
      a.landing-nav_signup {
        border-color: #B9EEB5;
        color: #B9EEB5;
      }


  // Page title

  .hero_title {
    @extend .h-alpha;

    margin: .66em 0 .75em;
    color: #FFF;
    text-align: center;

    .plain {
      letter-spacing: -.025em;
      font-size: .9em;
    }

    .nowrap {
      white-space: normal;

      @include large {
        white-space: nowrap;
      }
    }
  }
  .hero_subtitle {
    margin: -1.2em 0 1.2em;
    color: #FFF;
    font-weight: normal;
    font-size: 1.6em;
    line-height: 1.25;
    text-align: center;
  }


  // Customers logos

  .customers {
    margin: 45px 0 30px;
    color: #B2F6AC;
  }
    .customers_trust {
      margin: 0 0 .66em;
      text-align: center;
    }
    .customers_logos {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: center;
      overflow: hidden;
    }
      .customer-logo {
        display: inline-block;
        height: 60px;
        margin: 0 20px;
        padding: 0;
        background: url('../images/landing/customers-logos.png') no-repeat;
        background-size: 492px 60px;
        text-indent: -999em;
        overflow: hidden;

        @include retina {
          background-image: url('../images/landing/customers-logos@2x.png');
        }

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .customer-wholefoods {
        width: 66px;
        background-position: 0 0;
      }
      .customer-philips {
        width: 120px;
        background-position: -68px 0;
      }
      .customer-intel {
        width: 68px;
        background-position: -190px 0;
      }
      .customer-disney {
        width: 82px;
        background-position: -260px 0;
      }
      .customer-citrix {
        width: 86px;
        background-position: -344px 0;
      }
      .customer-happycog {
        width: 60px;
        background-position: -432px 0;
      }


  // Testimonial

  .testimonial {
    position: relative;
    max-width: grid-width(4);
    margin: 0 auto 30px;
    padding-left: 105px;
    color: #B2F6AC;

    @include large {
      padding-left: grid-width(2);
    }
  }
    .testimonial_photo {
      position: absolute;
      top: .15em;
      left: (grid-width(1) - 70px - 30px);
      width:  70px;
      height: 70px;
      border: 2px solid rgba(#B2F8AD, .4);
      border-radius: 50%;

      @include large {
        left: (grid-width(2) - 70px - 30px);
      }
    }
    .testimonial_quote {
      margin: 0 0 .5em;
      color: mix(#B2F6AC, #FFF, 50%);
      font-size: 1.2em;
      line-height: normal;
      text-indent: -.35em;

      p {
        margin: 0;
      }
    }
    .testimonial_source {
      margin: 0;
      font-size: .9em;
    }
    .testimonial_more {
      margin: .5em 0 0;
      font-size: .9em;

      a:link,
      a:visited {
        color: inherit;
      }
    }


  .testimonial--code-review {
    margin-bottom: 60px;
    padding-left: grid-width(1);
    color: inherit;

    .testimonial_photo {
      left: (grid-width(1) - 70px - 30px);
    }
    .testimonial_quote {
      color: inherit;
      font-size: 1.1em;
    }
    .testimonial_source {
      color: mix(#AEEACE, #269699, 50%);
    }
  }



/* VCS
================================================= */

.sep-hero-vcs {
  margin: -18px auto 0;
  background: url('../images/landing/sep-hero-vcs-bg@2x.png') no-repeat;
  background-size: 100% 61px;

  @include medium {
    margin-top: -36px;
    background-size: 100% 123px;
  }
}
  .sep-hero-vcs_inner {
    height: 61px;
    background: url('../images/landing/sep-hero-vcs.png') no-repeat 50% 0;
    background-size: 640px 61px;

    @include medium {
      height: 123px;
      background-size: 1280px 123px;
    }

    @include retina {
      background-image: url('../images/landing/sep-hero-vcs@2x.png');
    }
  }

.sep-hero-vcs-puzzle {
  height: 36px;
  margin: -22px 0 0;
  background: url('../images/landing/sep-hero-vcs-puzzle.png') repeat-x;
  background-size: 53px 36px;

  @include medium {
    height: 73px;
    margin-top: -44px;
    background-size: 106px 73px;
  }

  @include retina {
    background-image: url('../images/landing/sep-hero-vcs-puzzle@2x.png');
  }
}

.sep-hero-cr {
  position: relative;
  margin: -18px auto 0;
  background: url('../images/landing/sep-hero-cr-bg@2x.png') no-repeat;
  background-size: 100% 61px;
  z-index: 100;

  @include medium {
    margin-top: -36px;
    background-size: 100% 123px;
  }
}
  .sep-hero-cr_inner {
    height: 61px;
    background: url('../images/landing/sep-hero-cr.png') no-repeat 50% 0;
    background-size: 640px 61px;

    @include medium {
      height: 123px;
      background-size: 1280px 123px;
    }

    @include retina {
      background-image: url('../images/landing/sep-hero-cr@2x.png');
    }
  }

.vcs {
  background-color: #E5F8BC;
  color: #617F1F;

  .section-head_title {
    border-right-color: #A3BB6D;
  }

  .feature {
    color: #608013;
  }
}


/* Code review
================================================= */

.sep-vcs-cr {
  height: 28px;
  margin-top: -14px;
  background: url('../images/landing/sep-vcs-cr.png') repeat-x;
  background-size: 326px 28px;

  @include medium {
    height: 56px;
    margin-top: -28px;
    background-size: 652px 56px;
  }

  @include retina {
    background-image: url('../images/landing/sep-vcs-cr@2x.png');
  }
}

.sep-vcs-cr-branches {
  margin: -18px auto 0;
  background: url('../images/landing/sep-vcs-cr-branches-bg@2x.png') no-repeat;
  background-size: 100% 61px;

  @include medium {
    margin-top: -36px;
    background-size: 100% 123px;
  }
}
  .sep-vcs-cr-branches_inner {
    height: 61px;
    background: url('../images/landing/sep-vcs-cr-branches.png') no-repeat 50% 0;
    background-size: 640px 61px;

    @include medium {
      height: 123px;
      background-size: 1280px 123px;
    }

    @include retina {
      background-image: url('../images/landing/sep-vcs-cr-branches@2x.png');
    }
  }


.code-review {
  background-color: #AEEACE;
  color: #269699;

  .section-head_title {
    border-right-color: #71B59E;
  }
  .container {
    padding-top: 45px;

    @include medium {
      padding-top: 90px;
    }
  }
}


/* Deployments
================================================= */

.sep-cr-deploy {
  margin-top: -14px;
  background-image: linear-gradient(to bottom, #68BAB6, #68BAB6);
  background-position: 0 13px;
  background-repeat: repeat-x;
  background-size: 5px 2px;

  @include medium {
    margin-top: -28px;
    background-position: 0 26px;
    background-size: 10px 3px;
  }
}
  .sep-cr-deploy_inner {
    height: 28px;
    background: url('../images/landing/sep-cr-deploy.png') no-repeat 50% 0;
    background-size: 740px 28px;

    @include medium {
      height: 56px;
      background-size: 1480px 56px;
    }

    @include retina {
      background-image: url('../images/landing/sep-cr-deploy@2x.png');
    }
  }

.deployments {
  background-color: #D5F1F1;
  color: #269699;

  .section-head_title {
    border-right-color: #7EC4C5;
  }
  .container {
    padding-top: 45px;
    overflow: hidden;

    @include medium {
      padding-top: 90px;
    }
  }
}
  .deploy-workflow {
    margin: 60px 0 45px;

    img {
      max-width: 980px;
      width: 100%;
      height: auto;
    }
  }


/* Security
================================================= */

.sep-deploy-security {
  margin-top: -17px;
  background-image: linear-gradient(to bottom, #859BAA, #859BAA);
  background-position: 0 17px;
  background-repeat: repeat-x;
  background-size: 5px 1px;

  @include medium {
    margin-top: -35px;
    background-position: 0 33px;
    background-size: 10px 3px;
  }
}
  .sep-deploy-security_inner {
    height: 45px;
    background: url('../images/landing/sep-deploy-security.png') no-repeat 50% 0;
    background-size: 640px 45px;

    @include medium {
      height: 90px;
      background-size: 1280px 90px;
    }

    @include retina {
      background-image: url('../images/landing/sep-deploy-security@2x.png');
    }
  }

.security {
  background-color: #CFE4EC;
  color: #5D6E86;

  .container {
    padding-bottom: 30px;
  }
}


/* Personal
================================================= */

.sep-wildbit {
  height: 10px;
  background: #147355 url('../images/landing/sep-wildbit.svg') repeat-x;
  background-size: 325px 10px;

  @include medium {
    height: 20px;
    background-size: 650px 20px;
  }
}

.wildbit {
  background-color: #5CAD69;
  color: #DCFFE2;

  a:hover,
  a:active {
    color: #FFF;
  }

  .section-head_title {
    color: #FFF;
  }
  .bright {
    color: #FFF;
  }
}


.team {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    margin: 0 5px 5px;

    @include large {
      margin: 0 10px 10px;
    }
  }
}
  $team-size: 44px;

  .team-photo {
    display: inline-block;
    width: $team-size;
    height: $team-size;
    border-radius: 50%;
    background: url('../images/landing/team.jpg') no-repeat;
    background-size: ($team-size * 12) $team-size;
    text-indent: -999em;
    overflow: hidden;
    box-shadow: 0 2px 1px 0 rgba(#B4F5AF, .2), inset 0 1px 2px 0 rgba(#000, .5);

    @include retina {
      background-image: url('../images/landing/team@2x.jpg');
    }

    &:link:hover {
      box-shadow: 0 2px 2px 0 rgba(#000, .1), inset 0 2px 2px 0 rgba(#FFF, .1);
    }
  }
  .team-photo--cnagele {
    background-position: 0 0;
  }
  .team-photo--nnagele {
    background-position: -($team-size * 1) 0;
  }
  .team-photo--efedorenko {
    background-position: -($team-size * 2) 0;
  }
  .team-photo--isabanin {
    background-position: -($team-size * 3) 0;
  }
  .team-photo--dsabanin {
    background-position: -($team-size * 4) 0;
  }
  .team-photo--achistyakov {
    background-position: -($team-size * 5) 0;
  }
  .team-photo--ibalos {
    background-position: -($team-size * 6) 0;
  }
  .team-photo--dchaby {
    background-position: -($team-size * 7) 0;
  }
  .team-photo--cbowler {
    background-position: -($team-size * 8) 0;
  }
  .team-photo--srice {
    background-position: -($team-size * 9) 0;
  }
  .team-photo--aharpp {
    background-position: -($team-size * 10) 0;
  }
  .team-photo--bkerr {
    background-position: -($team-size * 11) 0;
  }


.footer {
  margin: 60px 0;
  color: mix(#5CAD69, #DCFFE2, 33%);
  text-align: center;
  font-size: .75em;

  .container + & {
    margin-top: 0;
  }
}


